import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { PageHero, Seo } from "../components";
import moment from "moment";
import tw, { styled } from "twin.macro";

const EventPreviewWrapper = styled(Link)`
  ${tw`flex flex-col px-4 py-8 md:flex-row justify-between max-w-[600px] rounded-2xl w-full md:gap-x-4 text-left transition duration-300 ease-in-out`}
  box-shadow:12px 12px 24px 12px var(--gray-one);

  &:hover {
    transform: translateY(-8px);
    box-shadow: 8px 16px 24px 16px var(--gray-two);
  }

  &.future {
    ${tw`border border-solid border-okay`}
  }

  .event-img {
    ${tw`w-[240px] h-[240px] mx-auto`}
  }

  .event-info {
    ${tw`text-primary w-[240px] mx-auto`}
  }

  .event-info > h3 {
    ${tw`antialiased text-base`}
  }

  .event-info > time {
    ${tw`text-sm opacity-90`}
  }

  .event-info > p {
    ${tw`text-sm whitespace-normal mt-2 px-0 mx-0`}
  }
`;

const EventPreview = ({
  id,
  slug,
  photo,
  title,
  datetime,
  summary,
  future,
}) => {
  const content =
    summary?.length > 120 ? `${summary.slice(0, 120)}...` : summary;

  return (
    <EventPreviewWrapper
      to={`/event/${slug}`}
      key={id}
      className={future ? "future" : ""}
    >
      <div className="event-img">
        {photo ? (
          <GatsbyImage image={getImage(photo)} alt={photo.description} />
        ) : (
          <StaticImage
            src={"../images/william-white-TZCppMjaOHU-unsplash.jpg"}
            alt="Live concert with vivid red ambient light"
            placeholder="blurred"
            layout="constrained"
            width={240}
          />
        )}
      </div>
      <article className="event-info">
        <h3>{title}</h3>
        <time>{moment(datetime).format("MMMM Do, YYYY")}</time>
        <p>{content}</p>
      </article>
    </EventPreviewWrapper>
  );
};

const EventListWrapper = styled.div`
  ${tw`rounded-2xl px-4 py-8 md:px-4 md:mx-auto my-9 shadow-two text-primary grid grid-cols-1 xl:grid-cols-2 bg-secondary gap-y-8 gap-x-2 place-items-center`}

  .list-name {
    ${tw`col-span-full pl-6 xl:justify-self-start`}
  }
`;

const EventListTemplate = ({ data }) => {
  const events = data.allContentfulEvent.nodes;

  const futureEvents = events.filter(({ datetime }) => {
    const eventDate = new Date(datetime);
    const currDate = Date.now();
    return eventDate > currDate;
  });
  futureEvents.sort((eventA, eventB) => {
    return new Date(eventA.datetime) - new Date(eventB.datetime);
  });

  const pastEvents = events.slice(futureEvents.length);

  return (
    <>
      <Seo
        title="Event"
        description="Explore our fun and meaningful events in the past!"
      />
      <PageHero
        img={
          <StaticImage
            src={"../images/william-white-TZCppMjaOHU-unsplash.jpg"}
            alt="Live concert with vivd red ambient light"
            placeholder="blurred"
            layout="constrained"
            width={3840}
            height={2160}
            loading="eager"
            className="hero-img"
          />
        }
        title="Events"
        text="Become a part of our community by joining events!"
      />
      <div className="page-main">
        <EventListWrapper>
          {futureEvents.length ? (
            <>
              <h3 className="list-name">Future Events</h3>
              {futureEvents.map(
                ({ id, title, slug, datetime, summary, photo }) => {
                  return (
                    <EventPreview
                      key={id}
                      id={id}
                      slug={slug}
                      photo={photo ? photo[0] : null}
                      title={title}
                      datetime={datetime}
                      summary={summary}
                      future
                    />
                  );
                }
              )}
            </>
          ) : null}

          {pastEvents ? (
            <>
              <h3 className="list-name">Recent Events</h3>
              {pastEvents.map(
                ({
                  id,
                  title,
                  slug,
                  datetime,
                  summary: { summary },
                  photo,
                }) => {
                  return (
                    <EventPreview
                      key={id}
                      id={id}
                      slug={slug}
                      photo={photo ? photo[0] : null}
                      title={title}
                      datetime={datetime}
                      summary={summary}
                    />
                  );
                }
              )}
            </>
          ) : null}
        </EventListWrapper>
      </div>
    </>
  );
};

export const query = graphql`
  query eventListQuery {
    allContentfulEvent(sort: { order: DESC, fields: datetime }) {
      nodes {
        title
        id
        datetime
        slug
        summary
        photo {
          gatsbyImageData(
            width: 240
            height: 240
            resizingBehavior: THUMB
            layout: FIXED
            placeholder: BLURRED
          )
          description
        }
      }
    }
  }
`;

export default EventListTemplate;
